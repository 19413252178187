

nav.navbar.navbar-expand-lg.navbar-light {
    width: 100%;
    position: fixed;
    background: linear-gradient(90deg, rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.1));
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
  
    justify-content: space-between;
    padding: var(--padding-xl) 91px 20.5px 66px;
    box-sizing: border-box;
    top: 0;
    z-index: 99;
    line-height: normal;
    letter-spacing: normal;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--font-size-mid);
    color: var(--color-dimgray);
    font-family: var(--font-montserrat);
}



.nav2.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus{
  color: white!important;
}

 a.nav-link {
    color: #696969 !important;
}
a.nav-link.active {
    color: white !important;
}
.home-container {
    background: #121212;
    padding: 0px;
    padding-top: 100px;
    margin-top: -100px;
}
.line-container{
    background-image: url('../media/lines.svg');
    margin-top: 190px;
}

.tagline{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.tagline h1{
    color: #565656;
    font-weight: 800;
    width: 100%;
    text-align: center;
    font-size: 100px;
    margin-top: 0px;
    margin-bottom: 60px;
}
#white{
    color: white;
}
.tagline p{
    width: 60%;
    text-align: center;
    font-size: 22px;
    padding: 20px;

    color: #565656;
}
.btn-two{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}
.btn-two button{
    margin: 10px;
    cursor: pointer;
}

#primary-btn,
#secondary-btn {
    padding: 10px 40px;
    border-radius: 13px!important;
    background-color: #0068ff;
    color: white;
    font-weight: 600;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
}

#secondary-btn {
    background-color: #121212;
    border-left: 1px #696969 solid;
    border-right: 3px #696969 solid;
    border-bottom: 3px #696969 solid;
    border-top: 1px #696969 solid;
    overflow: hidden;
}

#primary-btn:hover,
#secondary-btn:hover {
    transform: translateY(0px); 
}

#primary-btn::before,
#secondary-btn::before {
    content: "";
    position: absolute;
    top: 0;
    border-radius: 1px!important;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(0, 255, 255, 0.5); 
    transition: width 0.2s ease; 
  
}

#primary-btn:hover::before,
#secondary-btn:hover::before {
    width: 100%;
   
}

.btn-two p{
    width: auto;
    padding: 20px;
    font-size: 18px;
    margin-top: 15px;
    font-weight: 600;
}

.footer{
    width: 100%;
 background-color: #266DF0;
 padding-bottom: 100px;
 margin-top: 0px;
}
.highlighted{
    padding: 100px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../media/high.svg');
    width: 100%;
    margin-top: 0px;

}

.highlighted h1{
    font-size: 75px;
    font-weight: 800;
    color: #6E9DF5;
}
.light{
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
    margin-left: -10px;
    margin-top: 50px;
}
.light #primary-btn{
    background-color: #5E87F8;
}
.light #secondary-btn{
    background-color: #266DF0;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
}
.end{
    background:#1A1B1D;
    padding-top: 100px;
}
.flex-row{
    display: flex;
    flex-direction: row;
}
.footer-brand{
    display: flex;
    width: 20%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
}
.footer-menu{
    display: flex;
    flex-direction: row;
    width: 80%;
    padding: 0px;
}
.footer-menu-element{
    min-width: 20%;
    margin: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.footer-menu-element h2{
    color: #696969;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}
.footer-menu-element a{
    color: #575758;
    font-size: 16px!important;
    margin-top: 10px;
    text-decoration: none;
    font-weight: 500;
}


.footer-brand img {
    width: 100px;
    display: block;
    margin-top: 20px;
}

.chat-btn{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 40px;
}
.chat-btn button{
    all: unset;
}
.end-t{
    background-color: #121212;
    position: 20px;
    display: flex;
    flex-direction: row;
    padding: 60px;
}

.end-t a{
    all: unset;
    color: #696969!important;
    font-size: 18px!important;
    margin-left: 40px;
    margin-bottom: 0px;
}
.copyright{
    text-align: left!important;
    margin-left: -40px;
}
.mg-60{
   
    width: 60%;
}
.mg-50{
    
    width: 50%;
}
.mg-40{
    
    width: 40%;
}
.mg-40 img{
 width: 300%;
}
.header-btn #secondary-btn{
    margin-right: 10px;
}
.footer-social{
    display: flex;
    flex-direction: row;
    margin-left: -25px;
}
.footer-social img{
    width: 20px;
    float: left;
    margin-left: 25px;
    height: 20px;
}

.flex-box{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
}
.boxes {
    flex: 1; 
    text-align: center; 
    padding: 0px; 
    width: 25%;
    border-radius: 20px;
    display: flex;
    border-left: 8px #232323 solid;
    border-top: 1px #232323 solid;
    border-right: 2px #232323 solid;
    border-bottom: 8px #232323 solid;
    color: white;
    margin: 5px; 
    margin: 20px;
    flex-direction: column;
    background-color: #121212;
    padding-top: 20px;
    overflow: hidden;
  }
  .boxes h3{
    color: #A3A3A3;
    font-weight: 600;
    padding-left: 20px;
    font-size: 20px;
    text-align: left;
  }
  button.woot-widget-bubble.woot-elements--right {
    width: 130px;
    margin: 30px;
    opacity: 0;
}
a.branding--link.justify-center.items-center.leading-3 {
    display: none!important;
    opacity: 1;
}
.container{
   
    width: 2000px;
}
#new{
    background-color: #0068ff;
    font-size: 10px;
    color: white;
    width: 100px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 1px;
}

.boxes p {
    font-size: 15px;
    width: 100%;
    text-align: left;
}
img#team {
    min-width: 150%;
    margin-left: -162px;
    margin-top: 100px;
}

.jobs-div {
    padding: 10px;
}

.jobs-div {
    display: flex;
    flex-wrap: wrap;
}

.small-div{
    display: flex;
    flex-wrap: wrap;
}
.exp{
    margin: 10px;
    font-size: 12px;
}
.jobs {
    flex: 0 0 calc(33.33% - 10px);
    margin: 5px; 
    background-color: white;
    padding: 20px;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(30,10,58,.04);
}

.white{
    background-color: #f8f9fa;
    height: auto;
}
.jobs h2{
    font-size: 16px;
    font-weight: 800;
    margin-left: 10px;
}
.jobs p{
    font-size: 14px;
    font-weight: 600;
    opacity: 0.7;
    margin-left: 10px;

}
.jobs h4{
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
    margin-top: 10px;
}
.desc {
    margin-left: 10px;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.applybtn{
    display: flex;
    margin-top: 20px;
}

.applybtn a{
    cursor: pointer;
    font-size: 12px;
    background-color: #0068ff;
    padding: 4px;
    font-weight: 500;
    color: white!important;
    width: 120px;
    text-align: center;
    border-radius: 10px;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
}



.applybtn a::before {
    width: 100%;
   
}
.applybtn a:hover {
    background-color: black;
    transform: translateY(0px); 
    border-left: 1px #111 solid;
    border-right: 3px #111 solid;
    border-bottom: 3px #111 solid;
    border-top: 1px #111 solid;
}
.companieslog
{
    width: 100%;
}
.companieslog h3{
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 50px;
}

.upcoming h1{
    font-size: 50px;
    font-weight: 700;
    color: #1A1B1D;
}
.upcoming {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    flex-wrap: wrap;
}
.hc-div {
    flex: 0 0 calc(33.33% - 10px);
    margin: 5px; 
    background-color: #5E87F8;
    padding: 20px;
    max-width: 33.33%;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    border-radius: 40px;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(30,10,58,.04);

}

.hc-div img{
    border-radius: 20px;

    float: left;
}
.hc-div h2{
    color: white;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;

    float: left;
}
.multi{
    background-color:#266DF0;
    height: 100%;
}

.runs{
    display: flex;
    flex-direction: row;
    float: left;
    padding: 0px;
    color: white;
    margin-bottom: 20px;
}
.rn-d{
    width: 100%;
    display: flex;
    flex-direction: column;
   
}
.rn-inner{
    margin-top: 10px;
}
.btn button {
    border-radius: 0px !important;
    background: #0068ff;
    color: white;
    padding: 10px;
    width: 100%;
    border-radius: 13px !important;
    margin-top: -44px;
}
.text-w{
    color:#fff;
}
.text-w h1{
    color: white;
    font-weight: 700;
}
.text-w p{
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
}

.boxes.img{
    border: none;
    flex-direction: column-reverse;
    justify-content:flex-end;
}

.boxes.img img {
  
    flex: 1 1;
    text-align: center;
    padding: 0px;
    border-radius: 20px;
    display: flex;
    color: white;
    margin: 5px;
    margin: 20px;
    flex-direction: column;
    background-color: #121212;
    padding-top: 20px;
    overflow: hidden;

}
.text-w {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#btn{
    color: #fff;
}

a{
    cursor:pointer !important;
}

.mt-div{
    flex: 0 0 calc(20% - 10px);
    margin: 5px; 
    background-color: #5E87F8;
    max-width:20%;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(30,10,58,.04);

}

.mt-div img{
  width: 100%;
  height: 200px;
  float: left;
  object-fit: cover;
  border-radius: 20px;
}
.mt-div h2{
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px;
}
.mt-div .ct-cp{
    color: #fff;
    font-size: 14px;
    font-weight: 600;
}

.comingsoon {
    display: none;
}
.btnn{
    display: none;
}


.clubs.mt-div {
    max-width:10%!important;
    padding: 5px;
    cursor: pointer;
    margin: 10px;
    padding-bottom: 0px;
}

.clubs.mt-div img{
    width: 100%;
    height: 100px;
    background-color: #fff;
}

.clubs.mt-div h2{
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
   
}

.commu{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.community-log{
    margin: 10px;
}

.community-log:nth-child(even){
    width: 100px;
    height: 150px;
}
.community-log:nth-child(odd){
    width: 150px;
    height: 100px;
}

.community-log img {
    border: solid #0068ff 0px;
    object-fit: contain;
    cursor: pointer;
    border-radius: 10px;
    width: 100%;
}


.community-log {
    position: relative; /* Add position relative to the parent container */
    border: solid #0068ff 0px;
    border-radius: 10px;
    margin: 5px;
}

.community-log img {
    width: 100px;
    height: 120px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 10px;
}

.community-log:hover::after {
    content: attr(data-name); /* Display the name */
    position: absolute;
    bottom: -20px; /* Adjust the position as needed */
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 999;
}


@media screen and (max-width: 1200px){
    body {
        padding: 0px !important;
    }
    .btnn{
        display: block;
    }
    body{
        background-color: transparent!important;    
    }
    #navTop {
        margin-top: 0px !important;
        width: 110%;
        float: left;
        padding: 41px;
        padding-left: 0px;
        padding-top: 30px;
        padding: 40px;
        padding-top: 28px;
    }
    .end {
        background: #1A1B1D;
        padding-top: 100px;
        width: 100%;
        overflow: hidden;
    }
   
    .footer-menu-element {
        min-width: 100%;
        margin: 10px;
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    .footer-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
    }
    .footer-menu-element h2 {
        color: #696969;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0px;
        margin-top: 32px;
    }
    .end-t {
        background-color: #121212;
        position: 20px;
        display: flex;
        flex-direction: row;
        padding: 0px;
    }
    .copyright {
        text-align: left !important;
        margin-left: -40px !important;
        margin-top: 40px;
    }
    .copyright a {
        font-size: 12px !important;
        color: #000;
        margin-bottom: 10px!important;
        text-decoration: none;
        font-family: "Space Grotesk", sans-serif;
        text-align: left;
        width: 100%;
        float: left;
    }

    .footer-brand {
        display: flex;
        width: 100%;
        padding: 10px;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 50px;
        display: none;
    }
    .footer-social {
        display: flex;
        flex-direction: row;
        margin-left: -25px;
        margin-top: 50px;
    }
    .flex-row {
        flex-direction: column ;
        display: flex;
    }
    .home-container {
        background: #121212;
        padding: 0px;
        padding-top: 160px;
        margin-top: -100px;
        overflow: hidden;
    }
    .tagline h1 {
        color: #565656;
        font-weight: 800;
        width: 100%;
        text-align: center;
        font-size: 45px;
        margin-top: -51px;
        margin-bottom: 0px;
    }
    br {
        display: none;
    }
    .tagline p {
        width: 100%;
        text-align: center;
        font-size: 22px;
        padding: 20px;
        color: #565656;
    }
    .btn-two {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }
    .btn-two img {
        width: 30%;
        margin-left: 35%;
        margin-top: 20px;
    }
    .flex-box.flex-row {
        display: flex;
        flex-direction: column !important;
    }
    .boxes {
        flex: 1 1;
        text-align: center;
        padding: 0px;
        width: 90%;
        border-radius: 20px;
        display: flex;
        border-left: 8px #232323 solid;
        border-top: 1px #232323 solid;
        border-right: 2px #232323 solid;
        border-bottom: 8px #232323 solid;
        color: white;
        margin: 5px;
        margin: 20px;
        flex-direction: column;
        background-color: #121212;
        padding-top: 20px;
        overflow: hidden;
    }
    .highlighted {
        padding: 0px;
        height: auto;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(http://localhost:3000/static/media/high.7197880….svg);
        width: 100%;
        padding-top: 40px;
        margin-top: 0px;
    }
    .highlighted h1 {
        font-size: 40px;
        font-weight: 800;
        color: #6E9DF5;
        width: 100%;
        position: relative;
    }
    .upcoming {
        display: flex;
        flex-direction: column;
        margin-top: 23px;
        flex-wrap: wrap;
    }
    .hc-div {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(33.33% - 10px);
        margin: 5px;
        background-color: #5E87F8;
        padding: 20px;
        max-width: 100%;
        flex-direction: column;
        display: flex;
        cursor: pointer;
        border-radius: 30px;
        overflow: hidden;
        box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .btn button {
        border-radius: 0px !important;
        background: #0068ff;
        color: white;
        padding: 10px;
        width: 107%;
        border-radius: 13px !important;
        margin-top: -44px;
    }
    .flex-mobile.flex-row {
        display: flex;
        flex-direction: column-reverse;
    }

.flex-mobile.flex-row {
    display: flex;
    flex-direction: column-reverse !important;
}
.flex-mobile.flex-row .mg-50,.flex-mobile.flex-row .mg-60{
    width: 100%;
}
.flex-mobile.flex-row  .mg-50 {
    width: 100%;
    margin-top: -97px;
}
#btn {
    color: #fff;
    padding: 0px;
    margin-bottom: 50px;
    margin-top: -82px;
    text-align: center;
}
.jobs-div {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
}
.companieslog {
    width: 100%;
    margin-bottom: 50px;
}
.companieslog h3 {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 50px;
    margin-top: 50px;
}
.mt-div {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    margin: 5px;
    background-color: #5E87F8;
    max-width: 100%;
    flex-direction: column;
    display: flex;
    cursor: pointer;
    border-radius: 20px;
    padding: 10px;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(30, 10, 58, .04);
}
.mt-div h2 {
    color: white;
    font-size: 20px;
    font-weight: 700;
    margin-top: 40px;
    margin-left: 10px;
}
.mt-div .ct-cp {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
}
.btn-two {
    width: 105%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}
.text-w h1 {
    color: white;
    text-align: center;
    font-weight: 700;
}
.text-w p {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 100px;
    text-align: center;
}
nav.navbar.navbar-expand-lg.navbar-light {
    width: 110%;
    position: fixed;
    background: linear-gradient(90deg, rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.1));
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    justify-content: space-between;
    padding: var(--padding-xl) 91px 20.5px 66px;
    box-sizing: border-box;
    top: 0;
    z-index: 99;
    line-height: normal;
    letter-spacing: normal;
    gap: var(--gap-xl);
    text-align: left;
    font-size: var(--font-size-mid);
    color: var(--color-dimgray);
    font-family: var(--font-montserrat);
    margin-right: -23px;
    margin-top: 55px;
}
.comingsoon {
    position: fixed;
    top: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1px;
    background: linear-gradient(90deg, rgba(18, 18, 18, 0.1), rgba(18, 18, 18, 0.1));
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    z-index: 999;
    height: 57px;
   
}
.comingsoon button{
    width: 40%;
    margin-left: 30%;
    background-color: #0068ff;
    color: white;
    font-weight: 600;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
    border-radius: 10px!important;
    font-size: 10px;
}
.comingsoon p{
    color: #fff;
    text-align: center;
    line-height: 0px;
    padding-top: 5PX;
    font-size: 12px;
}
div#navbarScroll {
    max-width: 50% !important;
    height: auto;
    background: #1C1C1C;
    float: right;
    margin-left: 50%;
    margin-top: 22px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0,0,0,.48);
    padding-bottom: 30px;
}

.btnn {
    float: left;
    width: 24%;
    margin-top: 0px;
    color: white;
}
.btnn a{
    color: white;
    text-decoration: none;
    background-color: #0068ff;
    color: white;
    font-weight: 600;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
    padding: 6px;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
}
.flex-mobile-rev.flex-row {
    display: flex;
    flex-direction: column !important;
}
.flex-mobile-rev.flex-row .mg-40{
    width: 100%;
}
img#team {
    min-width: 100%;
    margin-left: 0px;
    margin-bottom: -112px;
    margin-top: 0px;
}
.flex-mobile-rev.flex-row .mg-60 {
    width: 100%;
    margin-top: 65px;
}
#primary-btn, #secondary-btn {
    padding: 10px 40px;
    border-radius: 13px !important;
    background-color: #0068ff;
    color: white;
    font-weight: 600;
    border-left: 1px #3B7EDC solid;
    border-right: 3px #3B7EDC solid;
    border-bottom: 3px #3B7EDC solid;
    border-top: 1px #3B7EDC solid;
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
    font-size: 12px;
}
button.navbar-toggler {
    margin-top: -40px;
    float: left;
    background: red;
    width: 100px;
    height: 45px;
}
#menu{
    margin-top: 10px;
}
img#menu {
    margin-top: 22px;
    font-size: 20px;
    width: 25px;
    height: 25px;
}
.navbar-light .navbar-toggler:focus {
    color: transparent;
    background-color: transparent;
}
.navbar-light .navbar-brand {
    width: 50%;
    margin-top: -10px;
}
.highlighted.multi .container h1 {
    padding-top: 47px;
    margin-bottom: 100px;
}
.clubs.mt-div {
    min-width: 28%;
    padding: 5px;
    cursor: pointer;
    margin: 10px;
    padding-bottom: 0px;
}
.upcoming.club {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
a{
    text-decoration: none!important;
}
.community-log:nth-child(odd) {
    width: 88px;
}
.commu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
}
.mg-profiles {
    background-color: #fff;
    margin-left: -20px;
    float: left;
    width: 114%;
    padding-bottom: 20px;
    margin-top: 0px;
    padding: 36px;
}
.mg-profiles img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
}
.highlighted.white.com h1 {
    color: #1a1a1a;
    padding: 10px;
    font-size: 27px;
}
.community-log:nth-child(odd) {
    width: 33%;
}
}

@media (min-width: 768px) {
    .mobile-navbar {
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .desktop-navbar {
      display: none;
    }
  }
  

  .beam {
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .multigrad-image {
    width: 100%;
    height: auto;
  }
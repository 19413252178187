@media only screen and (max-width: 1200px) {
#top{
    display: none;
}

.cb-page h1 {
    color: #FFFFFF;
    font-family: mg-bold;
    margin: 0px;
    margin-top: 100px;
    font-size: 38px;
}

.cb-page p {
    font-family: mg-regular;
    font-size: 18px;
    color: #fff;
    margin-left: 0px;
    margin-top: 20px;
}

.cb-input {
    background-color: #fff;
    width: 100%;
    margin-left: 0px;
    padding: 10px;
    float: left;
    border: solid black 2px;
    border-radius: 100px;
    overflow: hidden;
    height: 50px;
    margin-top: 20px;
}

.cb-input input {
    width: 60%;
    background-color: #fff;
    padding: 15px;
    margin-top: -10px;
    font-size: 12px;
    font-family: mg-regular;
    float: left;
}

.cb-input button {
    background-color: #000;
    float: right;
    width: 40%;
    font-size: 16px;
    margin-top: -12px;
    margin-right: -15px;
}

.cb-input input:focus {
    outline: none;
}

.cb-page h2 {
    width: 100%;
    float: left;
    padding: 20px;
    color: #fff;
    font-family: mg-bold;
    margin-left: -20px;
    margin-top: 60px;
    font-size: 30px;
}

#top {
    float: left;
    margin-top: -250px;
    margin-left: 720px;
}

.mg-peoples {
    margin: 20px;
}

.mg-profiles {
    background-color: #fff;
    margin-left: -20px;
    float: left;
    width: 114%;
    padding-bottom: 20px;
    margin-top: 0px;
}

.mg-profiles button {
    width: 80%;
    float: left;
    margin-left: 10%;
    margin-bottom: 10px;
    background-color: white;
    border: solid black 2px;
    height: 38px;
    color: black;
    padding-top: 5px;
    font-family: mg-bold;
    margin-top: 10px;
}

.mg-profiles p {
    color: #273339;
    font-family: mg-medium;
    float: left;
    margin: 0px;
    font-size: 14px;
    text-align: center;
    overflow: hidden;
    width: 100%;
    margin-top: 10px;

}
.cb-page {
    background-color: #0068ff;
    width: 111%;
    float: left;
    height: auto;margin: 0px;
        margin-left: -20px;
        margin-right: -20px;
        padding: 20px;
        margin-top: -100px;}
    .footerContainer {
                    width: 110%;
                    margin-left: -20px;
                    margin-top: 40px;
                    float: left;
                    padding-top: 0px;
                    background-color: rgb(5, 0, 56);
                    padding-right: 40px;
                    margin-bottom: -40px;
                }
                                .mg-profiles img {
                                    width: 100%;
                                    height: 350px;
                                    object-fit: cover;
                                }

}


@media screen and (max-width: 1100px) {
    .connect{
        text-align: left;
    }
    .connect h3 {
            font-size: 20px;
            font-family: 'mg-semibold';
        }
  .home-div {
      width: 100%;
      background-color: white;
      float: left;
      box-shadow: 0 1.267198085784912px 1.267198085784912px #666d8014, 0 5.068792343139648px 10.137584686279297;
      border-radius: 10px;
      margin-top: 60px;
      padding: 0px;
      padding-top: 50px;
  }

    .userName-active {
        width: 100%;
        float: left;
    }

   .userName-active h1 {
       color: #363849;
       width: 100%;
       float: left;
       font-size: 20px;
       font-weight: 500;
       font-family: 'mg-regular';
   }
   .userName-active p {
    width: 50%;
    float: left;
    margin-left: 0px;
    font-family: 'mg-regular';
}
.name-profile-left p {
    color: #666D80;
    font-size: 18px;
    font-weight: 400;
    width: 150%;
    font-family: 'mg-medium';
    line-height: 30px;
}
    .name-profile {
        margin-top: 0px;
    }

  #lightBtn {
      background-color: #fff;
      height: 100%;
      border-radius: 6px;
      box-shadow: 0px 1px 1px 0px rgba(102, 109, 128, 0.08), 0px 4px 8px 0px rgba(102, 109, 128, 0.2), 0px 0px 0px 0px var(--token-17aea52f-75cc-4210-9cfa-54c6a6e98e8e, rgb(255, 255, 255)), 0px 0px 0px 0px rgba(53, 56, 73, 0.16);
      opacity: 1;
      color: #000;
      padding: 10px;
      float: left;
      width: 120px;
      border: solid #f9f9f9;
      cursor: pointer;
      margin-left: 10px;
      margin-top: 0px;
  }
 .btnCnt {
     display: flex;
     justify-content: flex-start;
     float: left;
     width: 100%;
     margin-top: 20px;
     margin-bottom: 60px;
 }
 .btnCnt a:first-child{
    margin-left: -20px;
 }
 #iconsSocial{
    margin-left:20px;
 }#darkIcon {
    float: left;
    opacity: 0.6;
    margin-top: 4px;
}

.btn {
    margin-left: -12px;
}
  .name-profile-right img{
    width: 100px;
    margin-top: 0px;
  }


    .projects {
        padding: 10px;
        background-color: #fff;
    }

    .ListOfProjects {
        width: 89%;
        height: auto;
        padding-top: 20px;
        padding-bottom: 0px;
    }
        .name-profile-left h2 {
            color: #363849;
            font-size: 36px;
            font-family: 'mg-extrabold';
        }

        nav.navbar.navbar-expand-lg.navbar-light{
            box-shadow: none;
            margin-top: -20px;
        }
        .navbar-light .navbar-brand{
            width: 50%;
        }
                .copyright p{
                    font-size: 12px;
                }
div#basic-navbar-nav{
    background: #fff!important;
}
#dropdown-basic {
    float: left;
    margin-top: 10px;
    font-weight: 500 !important;
    border-radius: 0px !important;
    margin-left: 10px;
    width: 100%;
    margin-left: 0px;
    text-align: left;
    margin-left: 5px;
}
p#signin{
    background-color: #fff;
    margin-left: 10px;
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    text-align: left;

}
#signup {
    font-weight: 500;
    font-size: 16px;
    background-color: #fff;
    padding: 8px;
    width: 180px;
    color: #000;
    text-align: left;
    margin-left: 10px;
    width: 90%;
    margin-left: 40px !important;
}
                .footer-one{
                    width: 100%;
                    margin-top: 30px;
                }
                                .mobileDown {
                                    display: block;
                                }
                                .footerContainer{
                                    padding: 20px;
                                }
                                .desktopDown{
                                    display: none;
                                }

                                #navTop {
                                    margin-top: 0px !important;
                                    width: 110%;
                                    float: left;
                                    padding: 41px;
                                    padding-left: 0px;
                                    padding-top: 30px;
                                }

.multigradLogo img {
    width: 50%;
}
.mgTagline {
    width: 100%;
    border-radius: 0px;
    padding: 0px;
    float: left;
    height: auto;
    margin-top: 50px;
    padding-bottom: 150px;
    padding-top: 60px;
    margin-left: -10px;
    display: block;
}

nav.navbar.navbar-expand-lg.navbar-light {
    box-shadow: none;
    margin-top: -20px;
    background: #0068ff;
    padding-left: 20px;
    margin-left: -20px;
}
body {
    background-color: #0068ff!important;
    overflow-x:hidden!important;
}

.leads-benefits .leads {
    width: 100%;
    margin-top: 40px;
    background-color: #5E87F8;
    margin: 10px;
    border-radius: 20px;
    padding: 20px;
}
.footer.black {
    height: auto;
}
.highlighted{
    padding: 20px!important;
}
.mt-div.contain{
    margin-bottom: 0px;
    padding: 0px;
}
.glacam{
    margin-bottom: 80px;
    width: 20%;
}
}
